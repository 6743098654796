import React, { useEffect, useState } from "react";
import ReactModal from 'react-modal';
import '../../assets/css/modals/informations.scss';
import Phone from '../Phone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from '../../config/config';
import axios from 'axios';
import FormData from "form-data";

function Informations(props) {
  /* eslint-disable */
  const phoneId = 'informationsModal';
  const LEAD_URI = `${config.api}/lead/${props.id}?access_token=${config.access_token}`;

  // Form Content
  const [form, setForm] = useState({});

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    target.classList.remove('danger-border')
    if (target.type === 'checkbox' && value) {
      let campo = document.getElementById('error-channels');
      if (!campo.classList.contains('d-none')) campo.classList.add('d-none');
    }

    setForm({ ...form, ...{ [name]: value } });
  }
  // Form Content

  function initData() {
    const defaultForm = {
      name: '',
      email: '',
      phone: '',
      message: ''
    }
    let dto = {}
    for (let i = 1; i <= config.channels.length; i++) {
      dto['via_' + i] = false;
    }
    setForm({ ...defaultForm, ...dto });
  }

  function setReply() {
    let retorno = ''
    for (let i = 1; i <= config.channels.length; i++) {
      if (form['via_' + i]) retorno += (retorno ? ',' : '') + i;
    }
    return retorno;
  }

  function checkValidation(dto) {
    let retorno = true, campo;
    for (let i in dto) {
      if (config.isEmptyNullOrUndefined(dto[i])) {
        campo = document.getElementById(i);
        campo.classList.add('danger-border');
        retorno = false;
      }
    }

    if (config.isEmptyNullOrUndefined(form['phone'])) {
      campo = document.getElementById('phone-informationsModal');
      campo.classList.add('danger-border');
      retorno = false;
    }

    if (!setReply()) {
      campo = document.getElementById('error-channels');
      campo.classList.remove('d-none');
      retorno = false;
    }
    return retorno;
  }

  async function sendLead(event) {
    event.preventDefault();
    let body = new FormData();
    body.append('name', form.name);
    body.append('email', form.email);
    body.append('phone', form.cc + (form.phone).replace(/\D/g, ""));
    body.append('message', form.message);
    body.append('reply', setReply());
    let isValid = checkValidation({ name: form.name, email: form.email, message: form.message });
    if (isValid) {
      let post = {
        method: 'post',
        url: `${LEAD_URI}`,
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: body
      }
      setSending(true);
      const res = await axios(post)
      if (res.data?.errors?.length) {
        window.alert(res.data.errors[0]);
      } else if (res.data?.response?.status) {
        initData();
        setSended(true);
      }
      setSending(false);
    }
  }

  useEffect(() => {
    initData();
  }, [])

  const [sending, setSending] = useState(false);
  const [sended, setSended] = useState(false);

  return (
    <ReactModal
      isOpen={props.show}
      contentLabel="Mais Informações"
      ariaHideApp={false}
      overlayClassName="overlay-informations"
      className="modal-informations"
    >
      {(!sended) ? <>
        <div className="text-center mb-50">
          <h1 className="mb-30">Mais Informações</h1>
          <span>Preencha os campos abaixo, responderemos em breve.</span>
        </div>
        <div className="grid col-2 id-cgap mb-40">
          <div className="grid id-gap">
            <div className="input-group">
              <label htmlFor="name">Nome completo</label>
              <input type="text" name="name" id="name" required="" value={form.name} onChange={handleInputChange} />
            </div>
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" required="" value={form.email} onChange={handleInputChange} />
            </div>
            <div className="input-group">
              <Phone emit={(data) => setForm({ ...form, ...data })} phoneId={phoneId} />
            </div>
          </div>
          <div className="input-group group-question">
            <label htmlFor="message">Tires duas dúvidas</label>
            <textarea name="message" id="message" className="w-100" style={{ height: '100%', display: 'block' }}
              required="" value={form.message} onChange={handleInputChange} />
          </div>
        </div>
        <div style={{ height: '30px' }}>
          <label id="error-channels" className="error bold d-none"> Informe pelo menos um canal de contato abaixo:</label>
        </div>
        <div className="grid col-3 sm-gap info-type-contact mb-50">
          {config.channels?.map((c, i) => {
            return (
              <label key={`channel_` + i + '_' + c.id} id={'via_' + c.id} className="flex ai-fc normal jc-fc">
                <input type="checkbox" name={`via_` + c.id}
                  checked={form['via_' + c.id]} onChange={handleInputChange} />
                <span>
                  <FontAwesomeIcon className="icon icon-contact float-left" icon={[(c.icone.split('-')[0]), (c.icone.split('-')[1])]} />
                  <div className="float-left">{c.label}</div>
                  <FontAwesomeIcon className={`icon ml-10 float-right ` + (!form['via_' + c.id] ? 'check-disabled' : '')}
                    icon={['fas', (form['via_' + c.id] ? 'check-circle' : 'circle')]} />
                </span>
              </label>
            )
          })}
        </div>
        <div className="flex jc-sb mb-40">
          <a href="https://portal.rankim.com/" className="flex ai-fc" onClick={props.hideModal}>Cancelar</a>
          <a className="btn action" onClick={sendLead} disabled={sending}>{`${sending ? 'Enviando..' : 'Pedir Mais Informações'}`}</a>
        </div>
      </> : <>
        <div className="text-center mb-20">
          <h1 className="mb-20">Obrigado!</h1>
          <span>Um dos nossos agentes responderá suas dúvidas em breve.</span>
        </div>
        <div className="scheduling-footer flex jc-sb ph-60">
          <span className="flex ai-fc"></span>
          <a href="https://portal.rankim.com/" className="btn action" onClick={props.hideModal}>OK</a>
        </div>
      </>}
    </ReactModal>
  );
};

export default Informations;