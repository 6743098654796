import React, {Fragment, useState} from 'react';
import axios from 'axios';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../assets/css/search.scss';
import {useHistory} from "react-router-dom";


function Search(props) {
  const defaultInputValue = props?.defaultInputValue || '';
  const SEARCH_URI = 'https://api.rankim.com.br/suggestions?access_token=14235a62a41ea9e3880868d4cf63494488355e7b9607ad657693f33203bc4b20';
    
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [text, setText] = useState(defaultInputValue);
  
  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem('filterStorage', JSON.stringify({text: text, negotiation: ''}));
    if (history.location.pathname === "/busca") window.location.reload();
    history.push("/busca");
  }
  
  const handleSuggestions = async (query) => {
      setIsLoading(true);
      const res = await axios.get(`${SEARCH_URI}&text=${query}`);
      setOptions(res.data);
      setIsLoading(false);
  };
  
  const filterBy = () => true;

  const handleBlur = () => {
    if(!text) {
      props.innerRef?.current?.clear();
    }
  }

  return (
    <form id="async-search-form" className="search-form w900 mb-30" onSubmit={handleSubmit}>
        <AsyncTypeahead
          filterBy={filterBy}
          id="async-search"
          isLoading={isLoading}
          promptText="Digite para pesquisar"
          searchText="Procurando..."
          minLength={3}
          defaultInputValue={defaultInputValue}
          onSearch={handleSuggestions}
          onChange={(e) => setText(e[0])}
          onBlur={handleBlur}
          options={options}
          ref={props.innerRef}
          emptyLabel="Nenhuma equivalência encontrada"
          placeholder="Busque seu próximo imóvel.."
          renderMenuItemChildren={(option, props) => (
            <Fragment>
                <span>{option}</span>
            </Fragment>
          )}
        />
        
        <button type="submit" className={`btn big action`}>Encontrar</button>
    </form>
  );
};

export default Search;