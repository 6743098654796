import React, { useState } from "react";
import '../../../assets/css/modals/components/schedule.scss';
import Datepicker from '../../Datepicker';
import Timepicker from '../../Timepicker';
import config from "../../../config/config";
import axios from "axios";
import FormData from "form-data";


function Schedule(props) {
  const SCHEDULING_URI = `${config.api}/scheduling/${props.id}?access_token=${config.access_token}`;

  const [classDate, setClassDate] = useState(null);
  const [classTime, setClassTime] = useState(null);

  const [dto, setDto] = useState({ name: '', date: '', time: '' });

  const handleInputChange = (event) => {
    const target = event.target;
    target.classList.remove('danger-border');
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setDto({ ...dto, ...{ [name]: value } });
  }

  const scheduling = async (event) => {
    event.preventDefault();
    let isvalid = true;
    if (config.isEmptyNullOrUndefined(dto['name'])) {
      let name = document.getElementById('name');
      name.classList.add('danger-border');
      isvalid = false;
    }
    if (config.isEmptyNullOrUndefined(dto['date'])) {
      setClassDate('danger-border');
      isvalid = false;
    }
    if (!config.isEmptyNullOrUndefined(dto['date']) && config.isEmptyNullOrUndefined(dto['time'])) {
      setClassTime('danger-border');
      isvalid = false;
    }
    if (isvalid) {
      let body = new FormData();
      body.append('name', dto.name);
      body.append('phone', config.getPhone());
      body.append('schedule', `${dto.date} ${dto.time}:00`);

      let post = {
        method: 'post',
        url: `${SCHEDULING_URI}`,
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: body
      };
      setScheduledSend(true);

      const res = await axios(post)
      if (res.data?.response?.status) {
        setScheduled(true);
      }

      setScheduledSend(false);
    }
  }

  const [scheduled, setScheduled] = useState(false);
  const [scheduleSend, setScheduledSend] = useState(false);

  return (
    <div>
      {(!scheduled) ? <>
        <div className="scheduling-header text-center mb-80">
          <h1 className="mb-20">Visitar Imóvel</h1>
          <div className="sub-title">Nunca foi tão fácil agendar uma visita. Insira abaixo seu nome, selecione uma data e hora.</div>
        </div>
        <div>
          <div className="input-group mb-30 pl-30 pr-30">
            <label htmlFor="name" className="ml-30">Nome</label>
            <input type="text" name="name" value={dto.name} onChange={handleInputChange} id="name" />
          </div>
          <Datepicker classDate={classDate} onSetDate={(retorno) => {
            setDto({ ...dto, ...{ date: retorno } })
            setClassDate(null);
          }} />
          {dto.date
            ? <Timepicker classTime={classTime} className="mt-60" onSetTime={(retorno) => {
              setDto({ ...dto, ...{ time: retorno } });
              setClassTime(null);
            }} />
            : ''}
        </div>
        <div className="scheduling-footer flex jc-sb ph-60 mt-50">
          <a href="https://portal.rankim.com/" className="flex ai-fc" onClick={props.hideModal}>Cancelar Agendamento</a>
          <a href="https://portal.rankim.com/" className="btn action" disabled={scheduleSend} onClick={scheduling}>{`${scheduleSend ? 'Agendando..' : 'Agendar Visita' }`}</a>
        </div>
      </> : <>
        <div className="scheduling-header text-center mb-20">
          <h1 className="mb-20">Obrigado!</h1>
          <div className="sub-title">Um agente irá entrar em contato e confirmar a visita.</div>
        </div>
        <div className="scheduling-footer flex jc-sb ph-60">
          <span className="flex ai-fc"></span>
          <a href="https://portal.rankim.com/" className="btn action" onClick={props.hideModal}>OK</a>
        </div>
      </>}
    </div>
  );
}

export default Schedule;