
import './offer.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Search from "../../components/Search";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from "react";

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// install Swiper components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import NumberFormat from "react-number-format";
import PropertyCard from "../../components/property/Card";
import config from "../../config/config";
import axios from "axios";
import Maps from "../../components/Maps";
import Button from "../../components/Button";
import Tags from "../../components/Tags";
import { Base64 } from "js-base64";
import Scheduling from "../../components/modals/Scheduling"
import Informations from '../../components/modals/Informations';
import Share from '../../components/modals/Share';
import Login from '../../components/modals/Login';
import DetailSwiper from "../../components/DetailSwiper";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

library.add(fas, far);
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function Offer(param) {
  const id = (config.isBase64(param?.id) ? Base64.decode(param?.id) : param?.id);

  const [loading, setLoading] = useState(true);

  // Modal Login
  const [showLogin, setShowLogin] = useState(false);
  const openModalLogin = (event) => {
    event?.preventDefault();
    setShowLogin(!showLogin);
  }

  // Properties List
  const [property, setProperty] = useState({});

  // Opportunities List
  const [opportunities, setOpportunities] = useState({});

  // Locations List
  const [differentials, setDifferentials] = useState([]);
  const PROPERTY_URI = `${config.api}/property/${id}?access_token=${config.access_token}`;


  const getOpportunities = async (city) => {
    let getOpportunitie = await axios.get(`${config.api}/opportunities/${city}?access_token=${config.access_token}`);
    setOpportunities(getOpportunitie.data?.response)
  }

  const endereco = () => {
    let endereco = '';
    if (property.endereco) endereco += property.endereco;
    if (property.numero) endereco += (!endereco ? '' : ', ') + property.numero;
    if (property.complemento) endereco += (!endereco ? '' : ' - ') + property.complemento;
    if (property.bairro) endereco += (!endereco ? '' : ' - ') + property.bairro;
    if (property.cep) endereco += (!endereco ? '' : ' - ') + 'CEP ';
    return (<span>{endereco} <NumberFormat value={property.cep} displayType={'text'} format="#####-###" /></span>);
  }
  // Locations List

  // Modal Scheduling
  const [showScheduling, setShowScheduling] = useState(false);
  const openModalScheduling = (event) => {
    event.preventDefault();
    setShowScheduling(!showScheduling);
  }
  // Modal Scheduling

  // Modal Informations
  const [showInformations, setShowInformations] = useState(false);
  const openModalInformations = (event) => {
    event.preventDefault();
    setShowInformations(!showInformations);
  }
  // Modal Informations

  // Modal Share
  const [showShare, setShowShare] = useState(false);
  const openModalShare = (event) => {
    event.preventDefault();
    setShowShare(!showShare);
  }
  // Modal Share

  // Favorite
  const [favorite, setFavorite] = useState({});
  const saveFavorite = (event, id) => {
    event.preventDefault();
    if (!config.isAuthenticated()) {
      openModalLogin(event);
    } else {
      const isFavorite = config.addFavorite(id);
      addFavorite(isFavorite);
    }
  }

  const addFavorite = (isFavorite) => {
    setFavorite({
      icon: isFavorite ? ['fas', 'heart'] : ['far', 'heart'],
      text: isFavorite ? 'Favorito' : 'Favoritar',
      favoriteClass: isFavorite ? 'favorite' : ''
    });
  }
  // Favorite

  //  Properties Slides
  let breakpoints = {
    520: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1200: {
      slidesPerView: 4,
    }
  }
  //  Properties Slides

  useEffect(() => {
    const getData = async () => {
      const getProperty = await axios.get(`${PROPERTY_URI}`);
      setProperty(getProperty.data?.response);

      // Set favorite
      const isFavorite = config.isFavorite(getProperty.data?.response.id);
      addFavorite(isFavorite);

      // Render Differentials
      let differentials = [[], [], [], []];
      let list = getProperty.data?.response?.differentials || [];
      let countLine = config.diferenciais(Object.values(list).length);
      Object.values(list).forEach((e, i) => {
        let index = (i / countLine) | 0;
        differentials[index].push(e);
      });
      setDifferentials(differentials);
      await getOpportunities(getProperty.data?.response?.cidade_id);
      setLoading(false);
    }

    (async function getDataAsync() {
      await getData();
    })();
  }, [PROPERTY_URI])

  const [activeSlide, setActiveSlide] = useState();
  const getSlide = (e) => {
    e.preventDefault();
    if (Object.values(property?.imagens).length) {
      let slide = e.target.ariaLabel.split(' / ')[0];
      setActiveSlide(slide - 1);
      openDetailSwiper(e);
    }
  }

  const [showDetailSwiper, setShowDetailSwiper] = useState(false);
  const openDetailSwiper = (event) => {
    event.preventDefault();
    setShowDetailSwiper(!showDetailSwiper);
  }

  const openWhatsApp = async (e) => {
    if (!config.isAuthenticated()) {
      openModalLogin(e);
    } else {
      const { data } = await axios.post(`${config.api}/propertyOwnerWhatsApp/${id}?access_token=${config.access_token}`);
      if (data?.response) {
        window.open(data?.response, '_blank')
      }
    }
  };

  return loading ? '' : (
    <div className="offer">

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content={property.metatags.keywords} />
        <meta name="description" content={property.metatags.descricao} />
        <meta name="author" content="rankim" />
        <title>{property.metatags.titulo}</title>
        <meta property="fb:app_id" content="1423541691244149" />
        <meta property="og:type" content="product" />
        <meta property="profile:username" content="voceafrente" />
        <meta property="og:title" content={property.metatags.title} />
        <meta property="og:description" content={property.metatags.descricao} />
        <meta property="og:url" content={property.url} />
        <meta property="og:site_name" content="rankim" />
        <meta property="og:see_also" content={property.url_bairro} />
        <meta property="og:image" content={property?.imagens[0].substring(property?.imagens[0].lastIndexOf('/') + 1)} />
        <meta property="og:image:url" itemprop="image" content={property?.imagens[0]} />
        <meta property="og:image:type" content="image/jpg" />
      </Helmet>

      <Login show={showLogin} hideModal={openModalLogin} onPass={(e) => {
        e?.preventDefault();
        setShowLogin(false);
        openWhatsApp(e);
      }} />
      <Scheduling show={showScheduling} hideModal={openModalScheduling} id={property.id} />
      <Informations show={showInformations} hideModal={openModalInformations} id={property.id} />
      <Share show={showShare} hideModal={openModalShare} title={property.titulo} />
      <DetailSwiper show={showDetailSwiper} hide={openDetailSwiper} activeSlide={activeSlide} imagens={property?.imagens} />
      <Header />
      <main>
        <div className="container large pt-40">
          <Search />
        </div>
        <div className="container large title">
          <a href={property.url_bairro.replace('https://portal.rankim.com', '')}>
            <FontAwesomeIcon className="icon mr-10" icon={['fas', 'arrow-left']} />
          </a>
          {property.cidade}, {property.bairro}
        </div>
        <div className="offer-item mt-20">
          <Swiper navigation slidesPerView={1} breakpoints={breakpoints}>
            {(Object.values(property?.imagens || [])).map((images, i) => {
              return (<SwiperSlide onClick={getSlide} key={'img' + i} className="property-image" style={{ background: `url(${images}) center center / cover no-repeat` }} />)
            })}


          </Swiper>
        </div>

        <div className="container relative mt-40 mb-40">
          <section className="grid-offer grid col-10 sm-gap">
            <section className="span-7 description">
              <div className="flex mb-20">
                <Tags className="mr-10" tags={property.tags} />
                <div className="property-tag sale-rent">
                  <FontAwesomeIcon icon={['fas', 'star']} /> {property.negociacao}
                </div>
              </div>
              <div className="flex ai-fc jc-sb mb-20">
                <h5>{property.bairro}</h5>
                <span className="price">
                  <small>R$</small>
                  <NumberFormat value={parseFloat(config.propertyValue(property))} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                </span>
              </div>
              <p>{property.cidade}, {property.uf} - Brasil</p>

              <h6 className="mt-40 mb-20">Sobre o Imóvel</h6>

              <ul className="icons-offer overflow-hidden">
                {property.area && !config.isEmptyNullOrUndefined(parseInt(property.area)) ? (
                  <li className="float-left mr-30 mb-10">
                    <FontAwesomeIcon className="icon mr-10" icon={['fas', 'ruler-combined']} /> <br />
                    <span>{property.area} m²</span>
                  </li>
                ) : ''}
                {property.quartos && !config.isEmptyNullOrUndefined(parseInt(property.quartos)) ? (
                  <li className="float-left mr-30 mb-10">
                    <FontAwesomeIcon className="icon mr-10" icon={['fas', 'bed']} /> <br />
                    <span>{property.quartos} Dormitório{config.getPlural(property.quartos)}</span>
                  </li>
                ) : ''}
                {property.suites && !config.isEmptyNullOrUndefined(parseInt(property.suites)) ? (
                  <li className="float-left mr-30 mb-10">
                    <FontAwesomeIcon className="icon mr-10" icon={['fas', 'shower']} /> <br />
                    <span>{property.suites} Suite{config.getPlural(property.suites)}</span>
                  </li>
                ) : ''}
                {property.banheiros && !config.isEmptyNullOrUndefined(parseInt(property.banheiros)) ? (
                  <li className="float-left mr-30 mb-10">
                    <FontAwesomeIcon className="icon mr-10" icon={['fas', 'toilet']} /> <br />
                    <span>{property.banheiros} Banh.</span>
                  </li>
                ) : ''}
                {property.vagas && !config.isEmptyNullOrUndefined(parseInt(property.vagas)) ? (
                  <li className="float-left mr-30 mb-10">
                    <FontAwesomeIcon className="icon mr-10" icon={['fas', 'car-side']} /> <br />
                    <span>{property.vagas} vaga{config.getPlural(property.vagas)}</span>
                  </li>
                ) : ''}
              </ul>


              {!property.descricao ? '' : (<h6 className="mt-40 mb-30">Descrição</h6>)}
              <p>{property.descricao}</p>
            </section>

            <section className="span-3 offer-broker">
              <div className="w-100 overflow-hidden">
                {property.corretor_avatar ? (<LazyLoadImage className="float-left mr-20" alt={property.corretor_nome || 'Vendedor'} src={property.corretor_avatar} />) : ''}
                <div className="float-left">
                  <h5>{property.corretor_nome || 'Vendedor'}</h5>
                  <h6>{property.corretor_creci ? `CRECI ${property.corretor_creci}` : ''}</h6>
                </div>
              </div>
              <div className="offer-values mt-20">

                <div className="span-1 grid col-2 ai-fc">
                  <small className="span-1 ai-ge">ID</small>
                  <div className="span-1 ai-ge value">
                    {property.id}
                  </div>
                </div>

                <div className="span-1 grid col-2 ai-fc">
                  <small className="span-1 ai-ge">Código</small>
                  <div className="span-1 ai-ge value">
                    {property.codigo}
                  </div>
                </div>

                {property.valor_condominio && !config.isEmptyNullOrUndefined(parseInt(property.valor_condominio)) ? (<div className="grid col-2 ai-fc mb-10">
                  <div className="span-1 label">Condomínio</div>
                  <div className="span-1 grid col-2 ai-fc">
                    <small className="span-1 ai-ge">R$</small>
                    <div className="span-1 ai-ge value">
                      <NumberFormat value={parseFloat(property.valor_condominio)} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                    </div>
                  </div>
                </div>
                ) : ''}
                {property.valor_iptu && !config.isEmptyNullOrUndefined(parseInt(property.valor_iptu)) ? (<div className="grid col-2 ai-fc mb-10">
                  <div className="span-1 label">IPTU</div>
                  <div className="span-1 grid col-2 ai-fc">
                    <small className="span-1 ai-ge">R$</small>
                    <div className="span-1 ai-ge value">
                      <NumberFormat value={parseFloat(property.valor_iptu)} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                    </div>
                  </div>
                </div>
                ) : ''}
              </div>

              <div>
                <hr />
                <div className="flex jc-sb">
                  <Button onClickButton={(evt) => saveFavorite(evt, property.id)} icon={favorite.icon} className={`btn wish mt-20 mr-10 ` + favorite.favoriteClass} text={favorite.text} />
                  <Button icon={['fas', 'share-alt']} url={config.url} className="btn share mt-20" text="Compartilhar" onClickButton={(evt) => openModalShare(evt)} />
                </div>
                <Button onClickButton={(evt) => openModalInformations(evt)} className="btn action mt-20 w-100" text="Mais Informações" />
                <Button onClickButton={(evt) => openModalScheduling(evt)} className="btn action mt-20 w-100" text="Agendar Visita" />
                <a href="https://simulador.credihome.com.br/rankim" rel="noreferrer" className="btn info mt-20 w-100 a-center" target="_blank">Simular Financiamento</a>
              </div>

              {property?.whatsapp_ativo && <div style={{ marginTop: '28px' }}>
                <hr />
                <button onClick={(evt) => openWhatsApp(evt)} className="btn action mt-20 w-100 a-center">
                  <FontAwesomeIcon className="icon float-left" style={{ fontSize: '22px' }} icon={['fab', 'whatsapp']} />
                  <span>Falar por Whatsapp</span>
                </button>
              </div>}

            </section>

          </section>
          {!(Object.values(property.differentials || [])).length ? '' : (<h6 className="mt-40 mb-30">Diferenciais</h6>)}
          <section>
            <div className="grid col-4">
              {differentials.map((differential, i) => {
                return (
                  <ul key={'differential-' + i}>
                    {differential.map((label, l) => {
                      return (
                        <li key={'differential-' + i + '-label-' + l}><FontAwesomeIcon className="icon mr-10" icon={['fas', 'check']} /> {label}</li>
                      )
                    })}
                  </ul>
                )
              })}
            </div>
          </section>


          <h6 className="mt-40 mb-30">Localização</h6>
          <p>{endereco()} - {property.cidade}</p>

        </div>

        <div className="mb-40" style={{
          backgroundColor: '#343332',
          height: '195px', position: 'relative'
        }}>
          <Maps properties={[property]} zoom={15} />
        </div>

        {opportunities.length ? <div className="container relative mb-40">
          <h6 className="mt-40 mb-40">Conheça Também</h6>
          <div className="property-thumb-slider grid sm-gap col-3">
            {opportunities.map((property, e) => {
              return (
                <PropertyCard key={e} property={property} />
              )
            })}
          </div>
        </div>
          : ''}
      </main>
      <Footer />
    </div>
  );
}

export default Offer;
