import React from 'react';
import logo from '../assets/img/logo.svg';
import logo2 from '../assets/img/logo2.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Logo(props) {
    let url = (props.url) ? props.url : '/';
    return (
        <a href={url} className={`logo ${props?.className || ''}`}>
            <LazyLoadImage alt="Rankim" src={!props.lightGreen ? logo : logo2} />
        </a>
    );
};

export default Logo;