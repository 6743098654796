import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import home from '../pages/home/home';
import Offer from '../pages/offer/offer';
import realtor from '../pages/realtor/realtor';
import advertise from '../pages/advertise/advertise';
import Search from '../pages/search/search';
import register from '../pages/register/register';
import privacyPolicy from '../pages/privacyPolicy/privacyPolicy';
import error404 from '../pages/errors/404';
import config from "./config";
import {Base64} from "js-base64";

function Routes() {
    function checkParams(params) {
        let end = params.match.url.split('/').slice(-1)[0]
        let codigo = (end.replace('.html', '')).split('-').slice(-1)[0];
        if (config.isBase64(codigo) &&  !isNaN(Base64.decode(codigo))) {
            return <Offer id={codigo}/>;
        }
        return <Search params={params?.match?.params}/>;
    }
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={home} />
                <Route path="/busca" exact component={Search} />
                <Route path="/corretor" exact component={realtor} />
                <Route path="/anunciar" exact component={advertise} />
                <Route path="/criar-conta" exact component={register} />
                <Route path="/imovel/:id" exact component={Offer} />
                <Route path="/:uf/:city" exact component={checkParams} />
                <Route path="/:uf/:city/:neighborhood" exact component={checkParams} />
                <Route path="/termos-de-uso" exact component={privacyPolicy} />
                <Route component={error404} />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;