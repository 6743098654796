
import React, {useEffect, useState} from "react";
import PhoneInput from "react-phone-input-2";
import NumberFormat from "react-number-format";
import pt from 'react-phone-input-2/lang/pt.json';

function Phone(props) {
  const [isDialCode, setIsDialCode] = useState(true);
  const [formatPhone, setFormatPhone] = useState('(##) #########');
  const [cc, setCc] = useState('55');
  const [phone, setPhone] = useState('');
  
  function handleOnChange(value, data) {
    let array = (data?.format || '').split(' ');
    delete array[0];
    let formatPhone = array.join(' ').split('.').join('#');
    setFormatPhone(formatPhone);
    
    let selectedFlag = document.querySelector('.selected-flag');
    selectedFlag.title = `${pt[data.countryCode]}: +${data.dialCode}`;
    setIsDialCode(value === data.dialCode);
    setCc(value)
    if (props.emit) props.emit({ [props?.params?.[0] || 'cc']: value });
  }
  
  function validateKeyCode(evt) {
    let charCode = (evt.which) ? evt.which : evt.keyCode
    if ((isDialCode || evt.target.value?.length > 4) && ![8, 46, 37, 38, 39, 40].includes(charCode)) evt.preventDefault();
  }
  
  function validateDialCode() {
    if (!isDialCode) {
      setCc('55');
      if (props.emit) props.emit({ [props?.params?.[0] || 'cc']: '55' });
    } 
  }
  
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setPhone(value);
    if (props.emit) props.emit({ [props?.params?.[1] || 'phone']: value })
  }

  const checkPhone = () => {
    let phoneInput = document.getElementById('phone-'+props.phoneId);
    if(phone.replace(/\D/g, "").length !== (formatPhone.match(/#/g)||[]).length) {
      setPhone('');
      phoneInput.classList.add('danger-border');
    } else {
      phoneInput.classList.remove('danger-border');
    }
  }
  
  useEffect(() => {
    let selectedFlag = document.querySelector('.selected-flag');
    selectedFlag.title = `Brasil: +55`;
  }, []);
  
  useEffect(() => {
    setPhone('');
    setCc('55');
  }, [props.limpar]);

  return (
    <div className="grid col-12 md-gap">
      <div className="span-4 input-group">
        <label htmlFor="cc">País</label>
        <PhoneInput
          value={cc}
          enableSearch={true}
          searchPlaceholder={'Procurar'}
          searchNotFound={'Sem Resultados'}
          localization={pt}
          placeholder="" onChange={handleOnChange}
          onKeyDown={validateKeyCode}
          onBlur={validateDialCode}
          autoFormat={false}
        />
      </div>
      <div className="span-8 input-group">
        <label htmlFor="phone">Telefone</label>
        <NumberFormat format={formatPhone} name="phone" value={phone} id={`phone-`+props.phoneId} onChange={handleInputChange} onBlur={checkPhone} />
      </div>
    </div>
    );
}

export default Phone;