import React from 'react';
import '../assets/css/detailswiper.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import Button from "../components/Button";

function DetailSwiper(props) {
  /* eslint-disable */
    return (
      <div>
        {props.show ? 
          <div className="container-details">
            <div className="details-property-slider">
              <div className="w-100 grid col-1 on-mobile">
                <Button className="btn btn-fechar js-fe" text="Fechar" onClickButton={props.hide}/>  
              </div>
              <Swiper navigation slidesPerView="auto" grabCursor={true} effect="coverflow" spaceBetween={10} initialSlide={props.activeSlide}>
                    {(Object.values(props.imagens || [])).map((images, i) => {
                      return ( <SwiperSlide key={'img' + i} className="property-image" style={{ backgroundImage: `url(${images})` }} /> )
                    })}
                </Swiper>
            </div>
        </div>
        : ''}
      </div>
  );
}

export default DetailSwiper;