import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, {useState} from "react";
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import '../assets/css/datepicker.scss';

SwiperCore.use([Navigation]);


function Datepicker(props) {
  let meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  let day = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
  let days = [];
  let date = new Date();
  while (days.length < 90) {
    days.push({
      'date': date.getDate(),
      'day': day[date.getDay()],
      'month': meses[date.getMonth()],
      'completeDate': `${date.getFullYear()}-${date.getMonth()+1 < 10 ? '0' : ''}${date.getMonth()+1}-${date.getDate()+1 < 10 ? '0' : ''}${date.getDate()}`
    });
    date.setDate(date.getDate() + 1);
  }
  
  const [selectedDate, setSelectedDate] = useState([]);
  
  return (
      <div className={"datepicker-component relative " + (props.className || '')}>
        <Swiper className="datepicker-slider"
                navigation= {{nextEl: '.datepicker-next', prevEl: '.datepicker-prev'}}
                slidesPerView="auto" grabCursor={true} effect="coverflow"
                spaceBetween={28}>
          {(days || []).map((day, i) => {
            return (
              <SwiperSlide key={`img-${i}`} style={{width: '114px'}}>
                <div className={`datepicker ${selectedDate === day.completeDate ? 'selected' : ''} ${props?.classDate || ''}`}
                     onClick={
                              () => {
                                props.onSetDate?.(day.completeDate);
                                setSelectedDate(day.completeDate)
                              }
                            }>
                  <div className="day">{day.day}</div>
                  <div className="date">{day.date}</div>
                  <div className="month">{day.month}</div>
                </div>
              </SwiperSlide> )
          })
          }
        </Swiper>
        <div className="swiper-button-prev datepicker-prev"/>
        <div className="swiper-button-next datepicker-next"/>
      </div>
    );
}

export default Datepicker;