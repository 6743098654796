import React from "react";
import ReactModal from 'react-modal';
import '../../assets/css/modals/login.scss';
import LoginComponent from "./components/Login";

function Login(props) {
  return (
    <ReactModal 
        isOpen={props.show}
        contentLabel="Agendar Visita"
        ariaHideApp={false}
        overlayClassName="overlay-login"
        className={`modal-login `}
    >
      <LoginComponent close={props.hideModal} hideModal={props.hideModal} onPass={props?.onPass || (() => {})}/>
    </ReactModal>
  );
};

export default Login;