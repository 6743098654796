import React, {useState} from "react";
import Logo from "../../Logo";
import Stage from "./Stage";
import Button from "../../Button";
import Phone from "../../Phone";

function PersonalData(props) {
  
  const [form, setForm] = useState({...props.form, ...{
    name: '',
    email: '',
    cc1: '55',
    phone1: '',
    cc2: '55',
    phone2: '',
    creci: '',
  }});
  
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setForm({...form, ...{[name]: value}});
  }
  function nextStep() {
    props.setup(props.stage + 1, form);
  }
  
  return (
    <div className="container setup">
        <div className="register-realtor-header text-center mb-80">
          <Logo className="mt-20 d-block disabled" />
          <Stage stage={props.stage} />
          <div className="w-80 m-auto">
            <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed egestas id lorem ut molestie.</h1>
  
            <div className="grid col-2 id-gap mt-60">
              <div className="input-group">
                <label htmlFor="name">Nome completo</label>
                <input type="text" name="name"
                       value={form.name}
                       onChange={handleInputChange} id="name" required="" />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input type="email" name="email"
                       value={form.email}
                       onChange={handleInputChange} id="email" required="" />
              </div>
              <Phone params={['cc1', 'phone1']} emit={(data) => setForm({...form, ...data}) }/>
              <Phone params={['cc2', 'phone2']} emit={(data) => setForm({...form, ...data}) }/>
              <div className="input-group">
                <label htmlFor="creci">Creci</label>
                <input type="text" name="creci"
                       value={form.creci}
                       onChange={handleInputChange} id="creci" required="" />
              </div>
            </div>
            
            <div className="footer-buttons grid col-3 sm-gap jc-sb ai-gc mt-100">
              <Button onClickButton={(e) => props.close(e, true)} className="btn white w-100" text="Cancelar" />
              <a href="/" className="help">Precisa de ajuda?</a>
              <Button onClickButton={() => nextStep()} className="btn action w-100" text="Próximo" />
            </div>
          </div>

          
        </div>
      </div>
    );
}

export default PersonalData;