import React from 'react';
import config from "../config/config";

function Tags(props) {
  const className = props.className
  return (
  <div>
    {props.tags?.map((tag, i) => {
      return (     
        <div key={`tag_`+i+'_'+tag} className={`property-tag tag ` + className} 
        style={{ backgroundColor: `${config.tags[tag]?.cor}` }}>
          {config.tags[tag]?.label}
        </div>
      )
    })}
  </div>
  )
};

export default Tags;