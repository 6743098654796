import React from "react";
import ReactModal from 'react-modal';
import '../../assets/css/modals/scheduling.scss';
import Login from "./components/Login";
import Schedule from "./components/Schedule";
import config from "../../config/config";
import { useState } from "react";

function Scheduling(props) {

  const [isAuthenticated, setAuthenticated] = useState(config.isAuthenticated());

  return (
    <ReactModal
      isOpen={props.show}
      contentLabel="Agendar Visita"
      ariaHideApp={false}
      overlayClassName="overlay-scheduling"
      className={`modal-scheduling ` + (isAuthenticated ? 'p-ms' : '')}
    >
      {!isAuthenticated
        ? <Login hideModal={props.hideModal} setAuthenticated={setAuthenticated} />
        : <Schedule hideModal={props.hideModal} id={props.id} />
      }
    </ReactModal>
  );
};

export default Scheduling;