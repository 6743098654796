import React from "react";
import ReactModal from 'react-modal';
import '../../assets/css/modals/share.scss';
import {FacebookShareButton, WhatsappShareButton, EmailShareButton} from "react-share";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Share(props) {
  const shareUrl = window.location.href;
  const title = 'Rankim - ' + props.title;

  return (
    <ReactModal 
        isOpen={props.show}
        contentLabel="Compartilhar"
        ariaHideApp={false}
        overlayClassName="overlay-share"
        className="modal-share"
    >
      <div className="text-center mb-50">
        <h1 className="mb-30">Compartilhar</h1>
        <span>Selecione uma das opções abaixo:</span>
      </div>

      <div className="grid col-3 sm-gap mb-50">
        <FacebookShareButton
            url={shareUrl}
            quote={title}
          >
            <div className="share-button">
                <FontAwesomeIcon className="icon float-left" icon={['fab', 'facebook']} />
                <span className="float-left">Via Facebook</span>
            </div>
        </FacebookShareButton>
        <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
          >
            <div className="share-button">
                <FontAwesomeIcon className="icon float-left" icon={['fab', 'whatsapp']} />
                <span className="float-left">Via Whatsapp</span>
            </div>
        </WhatsappShareButton>
        <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
          >
            <div className="share-button">
                <FontAwesomeIcon className="icon float-left" icon={['far', 'envelope']} />
                <span className="float-left">Via Email</span>
            </div>
        </EmailShareButton>
      </div>
      
      <div className="flex jc-sb">
        <a href="https://portal.rankim.com/" className="flex ai-fc" onClick={props.hideModal}>Cancelar</a>
      </div>
    </ReactModal>
  );
};

export default Share;