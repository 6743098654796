import Cookies from 'js-cookie'

const debug = window.location?.href?.match(/localhost/ig) || false;

const config = {
    name: 'Portal Rankim',
    url: 'http://portal.rankim.com',
    release: '1.0.0',
    api: (debug) ? 'http://localhost/rankim-station/api' : 'https://api.rankim.com.br',
    access_token: '14235a62a41ea9e3880868d4cf63494488355e7b9607ad657693f33203bc4b20',
    keyGoogleMaps: 'AIzaSyDbkZ_pP8COzM_iBnH039p3PtVXfPb2GsM',
    tags: [],
    channels: [],
    totals: [],
    negotiations: {},
    
    // Utils
    getPlural: (value, singular = '', plural = 's') => {
        value = typeof value === 'string' ? parseInt(value) : value;
        return value <= 1 ? singular : plural;
    },
    
    isEmptyNullOrUndefined: (string) => {
        return !string || string === null || string === undefined || string === 0 || string === "" || string === "null";
    },
    
    diferenciais: (qtd = 0) => {
        let retorno = 5;
        let qtdDefault = 20;
        for (let i = 0; i < 10; i++) {
            if (qtd <= qtdDefault) {
                break;
            } else {
                qtdDefault += 4;
                retorno++;
            }
        }
        return retorno
    },
    
    propertyValue: (property) => {
        return parseInt(property?.negociacao_id) === 1 ? property.valor : property.valor_aluguel;
    },

    addFavorite: (id) => {
        if(id && Cookies.get('user')){
            const list = JSON.parse(Cookies.get(Cookies.get('user')) || '[]') || [];
            let newList = list;
            let isNewId = true;
            if(list.includes(id)){
                newList = list.filter((i) => {
                    return i !== id
                });
                isNewId = false;
            } else {
                newList.push(id);
            }
            Cookies.set(Cookies.get('user'), JSON.stringify(newList), { expires: 7, path: '/' });
            return isNewId
        }
    },

    isFavorite: (id) => {
        if (!Cookies.get('user')) return false;
        const list = JSON.parse(Cookies.get(Cookies.get('user')) || '[]') || [];
        return list.includes(id);
    },
    
    isBase64: (str) => {
        try {
            return btoa(atob(str)).replace('=', '') === str.replace('=', '');
        } catch (err) {
            return false;
        }
    },

    isAuthenticated: () => {
      return Cookies.get('user');
    },
    
    getPhone: () => {
      return Cookies.get('phone');
    },

    logout: () => {
        Cookies.remove('user');
        Cookies.remove('phone');
        window.location.reload();
    }
    
}

export default config;