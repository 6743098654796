import React from "react";
import Logo from "../../Logo";
import Stage from "./Stage";
import ListPlans from "../../ListPlans";

function Plans(props) {
  
  return (
    <div className="container setup">
        <div className="register-realtor-header plans text-center mb-80">
          <Logo className="mt-20 d-block disabled" />
          <Stage stage={props.stage} />
          <ListPlans className="mt-60" />
          
        </div>
      </div>
    );
}

export default Plans;