import './home.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Search from '../../components/Search';
import PropertyCard from '../../components/property/Card';
import anuncie from '../../assets/img/anuncie.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import NumberFormat from 'react-number-format';
import { Helmet } from "react-helmet";

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import config from "../../config/config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

library.add(far, fas)

function Home() {
    localStorage.removeItem('filterStorage');
    const refSearch = useRef(null);
    // Range Budget
    const [state, setState] = useState({ value: 200000 });
    const [clientBudget, setClientBudget] = useState(state.value * 5);
    const onChangeRange = range => {
        setState({ value: range });
        setClientBudget(range * 5);
    }
    // Range Budget

    //  Properties Slides
    let breakpoints = {
        520: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
        1200: {
            slidesPerView: 4,
        },
        1500: {
            slidesPerView: 5,
        }
    }

    //  Properties Slides

    // Filters
    const [filter, setFilter] = useState({
        negotiation: '1',
        property_type: 1,
        rooms_1: false,
        rooms_2: false,
        rooms_3: false,
        rooms_4: false,
        property_value: ''
    });

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setFilter({ ...filter, ...{ [name]: value } });
    }

    const history = useHistory();
    const handleSubmit = (event) => {
        event.preventDefault();
        let total = config.totals?.totals.find(t => t.tipo === config.negotiations[filter.negotiation || 1]) || {};
        let property_value = (filter?.property_value).toString().replace(/\./g, "") || total?.valor_max;
        let filterTemp = {
            ...filter, ...{
                value: {
                    min: parseInt(total?.valor_min),
                    max: parseInt(property_value)
                },
                value_min_input: parseInt(total?.valor_min),
                value_max_input: parseInt(property_value),
                value_min_input_old: parseInt(total?.valor_min),
                value_max_input_old: parseInt(property_value)
            }
        };
        localStorage.setItem('filterStorage', JSON.stringify(filterTemp));
        history.push("/busca");
    }

    const seeProperties = (event) => {
        event.preventDefault();
        let total = config.totals?.totals.find(t => t.tipo === config.negotiations[filter.negotiation || 1]) || {};
        localStorage.setItem('filterStorage', JSON.stringify({
            negotiation: '1',
            value: {
                min: parseInt(total?.valor_min),
                max: parseInt(clientBudget) || parseInt(total?.valor_max)
            },
            value_min_input: parseInt(total?.valor_min),
            value_max_input: parseInt(clientBudget) || parseInt(total?.valor_max),
            value_min_input_old: parseInt(total?.valor_min),
            value_max_input_old: parseInt(clientBudget) || parseInt(total?.valor_max)
        }));
        history.push("/busca");
    }
    // Filters

    const [loading, setLoading] = useState(true);

    // Properties List
    const [propertiesList, setPropertiesList] = useState([]);
    // Locations List
    const [locationsList, setLocationsList] = useState([]);
    const OFFERS_URI = `${config.api}/offers?access_token=${config.access_token}`;
    const LOCATIONS_URI = `${config.api}/locations?access_token=${config.access_token}`;

    useEffect(() => {
        const getData = async () => {
            const getOffers = await axios.get(`${OFFERS_URI}`);
            setPropertiesList(getOffers.data)
            const getLocations = await axios.get(`${LOCATIONS_URI}`);
            setLocationsList(getLocations.data.response);
            setLoading(false);
        }

        (async function getDataAsync() {
            await getData();
        })();
    }, [OFFERS_URI, LOCATIONS_URI])

    // Properties List

    return (
        <div className="home">
            
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="keywords" content="plataforma,automação,leads,corretor,whatsapp,landing page,captação de leads,crm corretor,crm imobiliária,marketing,rankim,geração de leads,corretor de imóveis" />
                <meta name="description" content="Plataforma de automação de marketing, captação de leads e CRM para corretores de imóveis e imobiliárias, conteúdo exclusivo" />
                <meta name="author" content="rankim" />
                <title>Rankim: Plataforma de compra e venda de imóveis</title>
                <meta property="fb:app_id" content="" />
                <meta property="og:type" content="article" />
                <meta property="profile:username" content="voceafrente" />
                <meta property="og:title" content="Plataforma imobiliária de marketing e CRM para.." />
                <meta property="og:description" content="Plataforma de automação de marketing, captação.." />
                <meta property="og:url" content="https://rankim.com.br/" />
                <meta property="og:site_name" content="rankim" />
                <meta property="og:see_also" content="https://rankim.com.br/" />
                <meta property="og:image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
                <meta property="og:image:url" itemprop="image" content="https://rankim.com.br/assets/website/images/video/02.jpg" />
                <meta property="og:image:type" content="image/jpg" />
            </Helmet>

            <Header />
            <main>
                <section id="main-banner">
                    <div className="container bg-pd">
                        <h1 className="small mb-40 text-center">Procurando que <span className="underline">tipo de imóvel?</span></h1>
                        <Search innerRef={refSearch} />
                        <div className="text-center mb-30">
                            Ou
                            </div>
                        <form className="filter-form grid w900" onSubmit={handleSubmit}>
                            <div className="flex ai-fc jc-fc buy-rent mb-20">
                                <label className="flex ai-fc">
                                    <input type="radio" checked={filter.negotiation === "1"}
                                        value="1" onChange={handleInputChange}
                                        name="negotiation" />
                                    <span>Comprar</span>
                                </label>
                                <label className="flex ai-fc">
                                    <input type="radio" checked={filter.negotiation === "2"}
                                        value="2" onChange={handleInputChange}
                                        name="negotiation" />
                                    <span>Alugar</span>
                                </label>
                            </div>
                            <div className="content-item gray grid sm-gap col-3">
                                <div className="grid sm-gap ph-20">
                                    <label className="flex ai-fc normal">
                                        <input type="radio" checked={parseInt(filter.property_type) === 1}
                                            value={1} onChange={handleInputChange}
                                            name="property_type" />
                                        <span className="mid-text ml-10">Apartamento</span>
                                    </label>
                                    <label className="flex ai-fc normal">
                                        <input type="radio" checked={parseInt(filter.property_type) === 2}
                                            value={2} onChange={handleInputChange}
                                            name="property_type" />
                                        <span className="mid-text ml-10">Casa</span>
                                    </label>
                                </div>
                                <div className="ph-20">
                                    <p className="mid-text mb-20">Dormitórios</p>
                                    <div className="flex ai-fc room-qnty">
                                        <label className="flex ai-fc normal">
                                            <input type="checkbox" name="rooms_1"
                                                checked={filter.rooms_1} onChange={handleInputChange} />
                                            <span className="mid-text">1</span>
                                        </label>
                                        <label className="flex ai-fc normal">
                                            <input type="checkbox" name="rooms_2"
                                                checked={filter.rooms_2} onChange={handleInputChange} />
                                            <span className="mid-text">2</span>
                                        </label>
                                        <label className="flex ai-fc normal">
                                            <input type="checkbox" name="rooms_3"
                                                checked={filter.rooms_3} onChange={handleInputChange} />
                                            <span className="mid-text">3</span>
                                        </label>
                                        <label className="flex ai-fc normal">
                                            <input type="checkbox" name="rooms_4"
                                                checked={filter.rooms_4} onChange={handleInputChange} />
                                            <span className="mid-text">4</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="ph-20">
                                    <p className="mid-text mb-20">Valor máximo</p>
                                    {/* onChange={handleInputChange} */}
                                    <div className="flex ai-fc jc-sb">
                                        <span className="mr-20 bold mid-text">R$</span>
                                        <NumberFormat name="property_value" id="property_value" className="property_value mid-text"
                                            value={filter.property_value} placeholder={filter.negotiation === "1" ? "400.000,00" : "1.500,00"}
                                            thousandSeparator={'.'} decimalSeparator={','} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-40">
                                <button type="submit" className="btn action">Filtrar imóveis</button>
                            </div>
                        </form>
                    </div>
                </section>


                <section id="home-properties">
                    {loading ? <div className="loaderx" style={{ marginTop: '35px' }} /> : (
                        <div className="mb-20">
                            <div className="img-back" />
                            <div className="container large relative pb-30">
                                <Swiper className="property-slider home-properties"
                                    spaceBetween={40}
                                    slidesPerView={1}
                                    breakpoints={breakpoints}
                                    pagination={{ el: ".prop-home-pag", clickable: true }}
                                >{propertiesList.map((property) => {
                                    return (
                                        <SwiperSlide key={property.id} className="property-thumb-slider">
                                            <PropertyCard property={property} />
                                        </SwiperSlide>
                                    );
                                })}

                                </Swiper>
                                <div className="swiper-pagination prop-home-pag swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-lock mt-10" />
                            </div>
                        </div>
                    )}
                </section>

                <section id="cities">
                    <div className="container md-pd">
                        <h2 className="text-center mb-40">Principais cidades</h2>
                        {loading ? <div className="loaderx" /> :
                            (<div className="grid col-6 md-gap ai-gc">
                                {locationsList.map((location, i) => {
                                    return (
                                        <a key={'location-' + i} href={location.link.replace('https://portal.rankim.com', '')} className="btn action dark text-center">{location.label}</a>
                                    );
                                })}
                            </div>)}
                    </div>
                </section>

                <section id="descubra">
                    <div className="container md-pd grid md-gap col-3">
                        <div className="text-center-mobile">
                            <h2 className="mb-20 bolder">Quer comprar?</h2>
                            <h2 className="mb-40 bolder">Descubra o seu potencial de compra</h2>
                            <p>Realizar um financiamento imobiliário é o sonho de muitos brasileiros, mas o mercado
                                imobiliário pode parecer confuso para quem não tem ligação com a área.</p>
                            <p>Com a Rankim fica fácil descobrir qual é o seu potencial de compra!</p>
                        </div>
                        <div className="tablet-none"></div>
                        <div className="text-center">
                            <p>Entrada + FGTS</p>
                            <p className="mb-40">
                                <span className="bold">R$</span>
                                <span className="big-text bold budget-info">
                                    <NumberFormat value={state.value} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                                </span>
                            </p>
                            <div className="budget-cont mb-40">
                                <InputRange
                                    minValue={5000}
                                    maxValue={1000000}
                                    step={5000}
                                    value={state.value}
                                    onChange={onChangeRange} />
                            </div>
                            <div className="content-item gray grid sm-gap mb-40">
                                <div>Seu potencial de compra é</div>
                                <p className="budget-price gg-text"><small>R$</small>
                                    <span className="budget-calculated">
                                        <NumberFormat value={clientBudget} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} />
                                    </span>
                                </p>
                                <a href="https://portal.rankim.com/#" className="btn big full action" onClick={seeProperties}>Ver imóveis nesse valor</a>
                            </div>
                            <p>O potencial de compra representa o valor máximo de um imóvel que você conseguirá financiar
                                baseado no valor de entrada inserido.</p>
                        </div>
                    </div>
                </section>

                <section id="anuncie-imovel" className="mb-40">
                    <div className="container md-pd grid ai-gc col-12">
                        <div className="img-cont span-4">
                            <LazyLoadImage src={anuncie} alt="Anuncie seu imóvel" />
                        </div>
                        <div className="span-8 grid sm-gap ai-gc col-2 text-center-mobile">
                            <div>
                                <h3 className="mb-20">Quer vender?</h3>
                                <h3 className="mb-20">Anuncie seu imóvel sem complicação.</h3>
                                <p>- Disponibilizamos uma vitrine qualificada que mostra todos os detalhes do seu imóvel para
                                    um grande número de compradores.</p>
                                <p>- Divulgamos seu imóvel em nosso site, nas redes sociais e em outros portais. </p>
                                <p>- Possuímos um time de profissionais totalmente focado em encontrar o comprador ideal para o seu imóvel.</p>
                                <p>Suas chances de vender ficam muito maiores com a Rankim!</p>
                                <p>Clique no botão “Anunciar agora", cadastre seu imóvel e aguarde o contato dos nossos especialistas.</p>
                            </div>
                            <div className="mt-20">
                                <a href="/anunciar" className="btn action">Anunciar agora</a>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="seja-corretor">
                    <div className="container bg-pd">
                        <h2 className="big text-center bolder mb-20">Seja um corretor parceiro!</h2>
                        <p className="w600 text-center">Como corretor parceiro você faz a captação e cadastra o imóvel no portal Rankim. Nós
                                trabalhamos por você buscando o cliente ideal e quando o imóvel for vendido você ganha uma comissão exclusiva.</p>
                    </div>
                    <div className="parceiro-home">
                        <div className="container grid col-4 md-gap">
                            <div className="content-item content-item-gtr v-pd gray grid">
                                <FontAwesomeIcon className="icon i-48" icon={['fas', 'briefcase']} />
                                <h4 className="bolder">Simplifique seu trabalho.</h4>
                                <p>Foque na sua captação, aumente sua carteira de imóveis e deixe a Rankim trabalhar por você.</p>
                            </div>
                            <div className="content-item content-item-gtr v-pd gray grid">
                                <FontAwesomeIcon className="icon i-48" icon={['fas', 'hand-holding-usd']} />
                                <h4 className="bolder">Receba quando vender.</h4>
                                <p>Com o imóvel indicado e vendido você recebe uma comissão exclusiva!</p>
                            </div>
                            <div className="content-item content-item-gtr v-pd gray grid">
                                <FontAwesomeIcon className="icon i-48" icon={['fas', 'chart-line']} />
                                <h4 className="bolder">Aumente suas vendas.</h4>
                                <p>Oferecemos as ferramentas e a tecnologia que um corretor precisa para obter muito sucesso.
                                        Venda mais imóveis com a Rankim trabalhando por você!</p>
                            </div>
                            <div className="content-item content-item-gtr v-pd gray grid">
                                <FontAwesomeIcon className="icon i-48" icon={['fas', 'arrow-right']} />
                                <h4 className="bolder">Quer saber mais?</h4>
                                <a href="/corretor" className="a-for-more">Não perca tempo! <br /> Cadastre-se agora clicando aqui.</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Home;